import React, { useState,useEffect,Dimensions,StyleSheet } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,  
  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
//import Background from './shared465.png'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactPlayer from 'react-player';

 var localizer = momentLocalizer(moment)


const MyCalendar = props => (
  <div className='calendar'>
    <Calendar
      localizer={localizer}
      events={[]}
      startAccessor="start"
      endAccessor="end"
      style={{ minWidth:'350px',width: '50vw',minHeight:'60vh'}}
    />
  </div>
);

const toggleCheckbox = (id)=> {
  document.getElementById(id).checked = !document.getElementById(id).checked;
}
function Events(props) {
  return (
    
      <MyCalendar/>
    
  );
}
function Videos(props) {
  return (
    <div className="feed">
            {<script async defer src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"></script>}  
     <span className='videos'> 
     <li><ReactPlayer url="https://www.facebook.com/DjJspinna/videos/dj-j-spinna-show/3859600680815924" controls/>
        <div className='thumb'>
          <b>Description:</b> 
          <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p>
          </div>
      </li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>

     </span>
      
    </div>
  );
}
function Music(props) {
  return (
    <div className="feed">
      <span className='music'> 
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>
      <li><img src="/imgs/genericAlbum.jpg"/><div className='thumb'><b>Description:</b> <p>blash blah blah blash blah blahblash blah blahblash blah blahblash blah blahblash blah blah</p></div></li>

     </span>
      
    </div>
  );
}
function Home(props) {
  return (
    <div className="feed">
      <div className='mediaPlayer'>
        <h2>Live Stream</h2>
          <h4>Next Stream 10/22/2021</h4>
          <img src='./imgs/play-button.png'/>
          <Link to='/music'>Chek Out Older Mixes</Link>
        
      </div>
    </div>
  );
}
function Bio(props) {
  return (
    <div className="feed">
<div className='bio'>
      <img src="imgs/shared501.jpg"/>
      <span>

        
         <p>DJ J Spinna is a lifelong resident of Boston, MA.  His love for music/dee-jaying began at the age of 14 when he saw his first set of turntables which was owned by Cousin Tony Dos Reis.  T-Reis was a respected Dee Jay with ties with Public Enemy and DJ Terminator X.</p>

         <p>DJ J Spinna sets a high mark of excellence for himself.  His chief strength is his ability to touch once’s soul with music.  With over 25 years of experience Spinna has shared the stage with multiple Cabo Verdean Artists/musicians, and icons</p>
         <p>DJ J Spinna is causing a buzz and attracting multiple platforms. His passion, energy and commitment is unquestionable. When not performing, he spends his time in the studio creating melodies/tracks and releasing new mixes for his One Love One Vibe fan base 
</p>
      </span>
  </div>
    
    </div>
  );
}

  function App() {

    return (
      <div>
        <Router>
     <header><img src='imgs/jspinna.png'/>
      <input type="checkbox" id="menyAvPaa"/>
    <label id="burger" for="menyAvPaa">
    <div></div>
    <div></div>
    <div></div>
    </label>
  <nav id="meny">
  <li>
        <Link  onClick={()=>toggleCheckbox('menyAvPaa')} to='/'>Home</Link> 
         </li>
         <li>
         <Link  onClick={()=>toggleCheckbox('menyAvPaa')} to='/bio'>Bio</Link> 
         </li>
         <li>
         <Link  onClick={()=>toggleCheckbox('menyAvPaa')} to='/music'>Music</Link> 
         </li>
         <li>
         <Link  onClick={()=>toggleCheckbox('menyAvPaa')} to='/videos'>Videos</Link> 
         </li>
         <li>
         <Link  onClick={()=>toggleCheckbox('menyAvPaa')} to='/events'>Events</Link> 
         </li>
         <li>
         <Link  onClick={()=>toggleCheckbox('menyAvPaa')} to='/contact'>Contact</Link>
         </li>
  </nav>
     <nav className='dmenu' >
     <li>
        <Link to='/'>Home</Link> 
         </li>
         <li>
         <Link to='/bio'>Bio</Link> 
         </li>
         <li>
         <Link to='/music'>Music</Link> 
         </li>
         <li>
         <Link to='/videos'>Videos</Link> 
         </li>
         <li>
         <Link to='/events'>Events</Link> 
         </li>
         <li>
         <Link to='/contact'>Contact</Link>
         </li>
    </nav>
    
    </header>
      <div className='content'>       
     <div className='feeds'>   

       <Switch>
        <Route exact path="/" render={props=>  <Home {...props}/>}/>
        <Route exact path="/videos" render={props=>  <Videos {...props} />}/>
        <Route exact path="/events" render={props=>  <Events {...props} />}/>
        <Route exact path="/music" render={props=>  <Music {...props} />}/>
        <Route exact path="/bio" render={props=>  <Bio {...props} />}/>
        </Switch>        
    </div>
      <ToastContainer />
      </div>
      </Router>
      </div>
      
    );
  }

export default App;
